const BimbankLogo = ({ full = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${full ? "202.6" : "62.7"} 62.7`}>
      <g id="mybim_logo">
        <path
          fill="#3D4975"
          d="M3.8,22.6c0.5-0.1,0.9-0.2,1.4-0.3h0.1c0.4-0.1,0.9-0.2,1.3-0.3h0.1c0.9-0.2,1.7-0.3,2.5-0.4
		c12.2-1.6,18.5,1.5,24,4.2c0,0,0,0,0.1,0c0.6,0.3,1.1,0.6,1.7,0.8c-0.4-4.5-1.8-9.5-6-15.5L28.6,11c-4.4-0.9-9.4-1.8-15.3-2.2
		c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-1.9,3.4-5.4,6.8-9.5,11.4l0,0l0,0c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.2,0,0.4,0.1,0.6
		s0.3,0.3,0.5,0.4C3.4,22.6,3.6,22.7,3.8,22.6z M29.3,11.6c-0.1-0.1-0.1-0.1-0.2-0.2C29.2,11.5,29.2,11.5,29.3,11.6z"
        />
        <path
          fill="#3D4975"
          d="M1.8,25.9c0.1,0.1,0.3,0.2,0.4,0.3c0.4,0.2,0.7,0.5,1,0.7c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.6,0.4,0.8,0.6
		c0.3,0.2,0.6,0.5,0.9,0.7c0.3-0.4,0.6-0.7,0.9-1.1s0.6-0.7,0.9-1.1c0.2-0.2,0.4-0.5,0.6-0.7c0.3-0.4,0.6-0.7,0.9-1.1
		c0.6-0.7,1.3-1.4,1.9-2.1c-0.6,0.3-1.4,0.4-2.1,0.5l0,0H8.5l0,0c-0.7,0.1-1.5,0.3-2.3,0.5l-0.3,0.1c-0.8,0.2-1.6,0.4-2.4,0.6
		l-0.3,0.1c-0.3,0.1-0.7,0.2-1,0.3c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.2s-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0,0.2,0.1,0.3C1.6,25.7,1.7,25.9,1.8,25.9z"
        />
        <path
          fill="#F09C22"
          d="M25.1,32.6c0-1.1-0.1-2.4-0.1-2.4c-0.6,0.4-1.2,0.9-1.9,1.4c0,0,0,0-0.1,0c-1.8,1.5-3.4,3.2-4.8,5l-0.1,0.1
		c-0.6,0.8-1.3,1.7-1.9,2.7v0.1c-0.4,0.6-0.8,1.3-1.2,1.9l-0.1,0.1c-0.1,0.2-0.2,0.4-0.4,0.6c0,0,0,0.1-0.1,0.1l-0.2,0.3
		c0,0.1,0.1,0.3,0.1,0.4V43c1.3,4.6,3.1,10.5,7.3,18.2c0,0,0,0,0,0.1c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2h13.5
		c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5s0.1-0.5,0.1-0.7s-0.2-0.5-0.3-0.6C26.7,48.1,25.5,39.6,25.1,32.6z"
        />
        <path
          fill="#D83968"
          d="M60.6,23.5c-0.4-0.2-0.7-0.5-1-0.8s-0.6-0.5-0.9-0.8l0,0c-0.3-0.3-0.5-0.5-0.8-0.8l0,0
		c-0.3-0.3-0.5-0.6-0.8-0.9l0,0c-0.2-0.3-0.4-0.6-0.7-0.9l-0.1-0.2c-0.2-0.3-0.4-0.7-0.6-1.1l0,0c-0.2-0.3-0.4-0.7-0.6-1.1
		c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.4-0.8-0.6-1.2c-0.1-0.2-0.3-0.4-0.5-0.5s-0.4-0.2-0.7-0.2s-0.5,0.1-0.7,0.2
		c-0.2,0.1-0.4,0.3-0.5,0.5c-0.3,0.7-0.6,1.4-0.9,2c-0.3,0.6-0.6,1.3-1,1.9v0.1c-4.6,8.6-10.2,13.3-15.3,16.5l0,0
		c-0.7,0.4-1.4,0.9-2.1,1.2c0.7,0.3,1.5,0.7,2.3,1l0,0c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.2l0.6,0.2
		c0.3,0.1,0.5,0.2,0.8,0.2c0.2,0,0.3,0.1,0.5,0.1c0.4,0.1,0.9,0.2,1.3,0.3c0.1,0,0.2,0,0.3,0.1c0.4,0.1,0.7,0.1,1.1,0.2l0.5,0.1
		c0.4,0,0.8,0.1,1.1,0.1l0.5,0.1c0.5,0,1.1,0.1,1.7,0.1l0,0c1.4,0,2.8,0,4.2-0.1c0.2,0,0.3,0,0.5,0c0.1-0.1,0.2-0.2,0.3-0.3l0.2-0.2
		c0.4-0.4,0.8-0.8,1.2-1.2l0.1-0.1c1.8-2,3.5-4.1,5.2-6.3c0.1-0.2,0.3-0.4,0.4-0.5c0.3-0.4,0.6-0.9,0.9-1.3c0.2-0.3,0.4-0.5,0.5-0.8
		c0.3-0.4,0.6-0.9,0.9-1.3c0.2-0.3,0.4-0.6,0.6-0.9c0.3-0.4,0.5-0.9,0.8-1.3c0.1-0.2,0.2-0.4,0.4-0.6l0,0c0.1-0.3,0.2-0.6,0.1-0.9
		C61,24,60.8,23.7,60.6,23.5z"
        />
        <path
          fill="#57A38C"
          d="M26.8,27.2c0.1,0,0.1-0.1,0.2-0.2c0.8-0.5,1.5-0.9,2.2-1.3c-0.8-0.4-1.6-0.7-2.5-1.1
		c-0.1,0-0.2-0.1-0.2-0.1c-2.8-1-5.7-1.6-8.7-1.6h-0.1c-0.4,0-0.7,0-1.1,0h-0.8c-0.4,0-0.7,0-1.1,0c-0.4,0-0.9,0-1.4,0.1H13h-0.1
		c-0.3,0-0.7,0.2-0.9,0.5c-4,4.3-7.6,9.1-10.7,14.2C1.1,38,1,38.4,1.1,38.8s0.3,0.7,0.6,0.9c2.6,1.9,4.3,4.1,5.8,7.4
		c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0,0.5,0,0.7-0.1s0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.2,0.2-0.4
		C15.1,36.5,21.2,30.8,26.8,27.2z"
        />
        <path
          fill="#E6542A"
          d="M57.6,40.4L57.6,40.4c-15.1,3.1-22.2-0.2-28.3-3.2l0,0c-0.8-0.4-1.5-0.8-2.3-1.1c0.1,0.8,0.2,1.7,0.3,2.5
		v0.1c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.5,0.2,0.7s0.1,0.4,0.2,0.6c0.1,0.3,0.2,0.7,0.3,1
		c0.1,0.2,0.1,0.5,0.2,0.7s0.1,0.4,0.2,0.6c0.1,0.4,0.3,0.8,0.4,1.1c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.3,0.3,0.6,0.4,0.9l0.2,0.5
		c0.2,0.4,0.4,0.9,0.7,1.3c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.4,0.5,0.8,0.7,1.2l0.3,0.4c0.3,0.4,0.6,0.8,0.9,1.3
		c0.1,0.1,0.1,0.2,0.2,0.3v0.1c0.1,0.1,0.2,0.3,0.3,0.4l0.5,0.1c4.3,0.8,9.1,1.7,14.9,2.1c0.3,0,0.5,0,0.8-0.2
		c0.2-0.1,0.4-0.3,0.6-0.5l0.5-0.9c0.2-0.3,0.3-0.5,0.5-0.8c1.9-2.8,4.7-5.6,7.9-9.2c0.2-0.2,0.3-0.5,0.3-0.8s0-0.6-0.2-0.8
		c-0.1-0.3-0.4-0.5-0.6-0.6C58.1,40.4,57.8,40.4,57.6,40.4z"
        />
        <path
          fill="#5ABDBD"
          d="M24.4,2.7C34.9,14,36.5,22.4,37,29.3v0.1c0.1,1.1,0.1,2.1,0.1,3.2c3.8-2.6,7.5-6.4,10.7-12.5l-0.2-0.7
		c-1.3-4.5-3.1-10.2-7.2-17.6c-0.1-0.3-0.4-0.5-0.6-0.6C39.6,1,39.3,0.9,39,0.9H25.1c-0.2,0-0.4,0.1-0.6,0.2s-0.3,0.3-0.4,0.5
		S24,2,24,2.2C24.1,2.4,24.2,2.6,24.4,2.7L24.4,2.7z"
        />
        <path
          fill="#5ABDBD"
          d="M22.3,3.9c0,0.4,0.1,0.8,0.1,1.2c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.4V7c0,0.5-0.1,0.9-0.1,1.3
		c2.2,0.3,4.3,0.7,6.3,1C28.4,9,28.2,8.8,28,8.5V8.4c-0.3-0.2-0.5-0.4-0.7-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-1.2-2-2.3-3.1-3.5
		l-0.3-0.3c-0.2-0.3-0.5-0.5-0.7-0.8c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2
		c0,0.1-0.1,0.2,0,0.3c0,0.1,0,0.2,0,0.3C22.3,3.6,22.3,3.8,22.3,3.9z"
        />
        <path
          fill="#F09C22"
          d="M39.9,59.1c-0.1-0.5-0.1-1-0.1-1.5v-0.2c0-0.5,0-1,0-1.5c0-0.1,0-0.2,0-0.2c0-0.4,0-0.8,0.1-1.2l-0.4-0.1
		c-1.7-0.2-3.2-0.5-4.8-0.7l-1.1-0.2l0.7,0.8c0,0,0,0,0,0.1c0.2,0.2,0.4,0.4,0.5,0.7l0.3,0.4c0.9,1.1,1.9,2.3,3,3.4
		c0.2,0.2,0.3,0.3,0.5,0.5s0.4,0.4,0.6,0.6l0,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1
		s0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2C40,59.4,40,59.2,39.9,59.1L39.9,59.1z"
        />
        <path
          fill="#D83968"
          d="M46.8,13.6c0.6,1.7,1.1,3.3,1.5,4.7l0,0l0,0c0.1,0.4,0.3,0.9,0.4,1.3c0.2-0.4,0.4-0.8,0.6-1.2l0,0
		c0-0.1,0.1-0.2,0.1-0.3c0.3-0.7,0.7-1.4,1-2.2c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.6,0.5-1.1,0.7-1.7c0.1-0.1,0.1-0.3,0.2-0.4
		c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0
		c-1,0.2-2,0.3-2.9,0.5h-0.3c-0.4,0-0.9,0.1-1.3,0.1C46.6,13.1,46.7,13.4,46.8,13.6L46.8,13.6z"
        />
        <path
          fill="#57A38C"
          d="M14.6,47.2c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.4-1.3-0.6-1.9c-0.2-0.5-0.3-1.1-0.5-1.6
		c-0.3,0.5-0.5,1-0.7,1.5c0,0,0,0,0,0.1c-0.4,0.7-0.7,1.5-1.1,2.3l-0.1,0.2c-0.3,0.7-0.6,1.4-0.9,2.2l-0.1,0.2l0,0
		c0,0.1,0,0.2,0,0.3s0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0l0,0c0.6-0.1,1.1-0.2,1.7-0.3
		h0.1c0.5-0.1,1-0.1,1.5-0.2c0.3,0,0.7-0.1,1-0.1c-0.1-0.4-0.3-0.8-0.4-1.2C15,48.3,14.8,47.8,14.6,47.2z"
        />
        <path
          fill="#E6542A"
          d="M60.7,37.5c-0.3-0.2-0.6-0.4-0.9-0.6l-0.3-0.2l-0.8-0.6l-0.2-0.2c-0.4-0.3-0.8-0.6-1.2-0.9l-0.8-0.6
		c-0.4,0.5-0.7,0.9-1.1,1.3c-0.1,0.2-0.1,0.3-0.2,0.4c-0.4,0.5-0.8,1-1.2,1.4l-0.2,0.2c-0.4,0.4-0.8,0.9-1.1,1.3l0,0
		c-0.4,0.5-0.8,0.9-1.2,1.3c0.5-0.1,1.1-0.1,1.7-0.2l0,0h0.1c0.5-0.1,1-0.2,1.5-0.3h0.1c0.6-0.1,1.1-0.2,1.7-0.3l0.3-0.1
		c0.5-0.1,1-0.2,1.6-0.4l0.3-0.1c0.6-0.2,1.2-0.3,1.9-0.5l0,0c0.1,0,0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2s0.1-0.1,0.1-0.2
		c0-0.1,0-0.2,0-0.2C60.9,37.7,60.8,37.5,60.7,37.5z"
        />
        <path
          fill="#F09C22"
          d="M30.9,28.4c-0.7,0-1.3,0.2-1.9,0.6s-1,0.9-1.2,1.5c-0.3,0.6-0.3,1.3-0.2,1.9c0.1,0.6,0.4,1.2,0.9,1.7
		s1.1,0.8,1.7,0.9c0.6,0.1,1.3,0.1,1.9-0.2c0.6-0.3,1.1-0.7,1.5-1.2s0.6-1.2,0.6-1.9l0,0c0-0.4-0.1-0.9-0.3-1.3s-0.4-0.8-0.7-1.1
		c-0.3-0.3-0.7-0.6-1.1-0.7C31.7,28.5,31.3,28.4,30.9,28.4L30.9,28.4z"
        />

        {full && (
          <>
            <path
              id="bimbank"
              fill="#3D4975"
              d="M78.7,33.8V9.9l3.9-1.3v9.5c1.3-0.7,2.7-1.1,4.2-1.1c2.3,0,4.3,0.8,5.9,2.5
	c1.7,1.6,2.5,3.6,2.5,5.9s-0.8,4.3-2.5,5.9c-1.6,1.6-3.6,2.4-5.9,2.4c-1.5,0-2.9-0.4-4.2-1.1v1.1h-3.9V33.8z M82.6,23.5L82.6,23.5
	c0,1.1,0.1,2.1,0.3,3.1c0.5,2,1.3,3.6,2.3,4.8c1.1,1.2,2.1,1.6,3.2,1.3s1.8-1.3,2.2-2.9c0.4-1.6,0.4-3.4-0.1-5.4s-1.2-3.6-2.3-4.7
	c-1.1-1.2-2.1-1.6-3.2-1.3c-0.5,0.2-0.9,0.5-1.3,1s-0.7,1.1-0.9,1.8S82.6,22.6,82.6,23.5z M97.6,33.8V18.3l3.9-1.3v16.8H97.6z
	 M99.5,10.9c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.4,0.3,0.8,0.3,1.2c0,0.7-0.2,1.2-0.7,1.7s-1.1,0.7-1.7,0.7
	c-0.7,0-1.2-0.2-1.7-0.7s-0.7-1.1-0.7-1.7c0-0.7,0.2-1.3,0.7-1.7C98.3,11.1,98.9,10.9,99.5,10.9z M104.1,33.8V18.3L108,17v1.5
	c1.2-1,2.7-1.5,4.2-1.5c1.2,0,2.4,0.3,3.5,1c1.1,0.6,2,1.5,2.7,2.7c0.5-1.2,1.3-2.2,2.2-2.8c1-0.6,2.1-0.9,3.3-0.9
	c2,0,3.8,0.8,5.2,2.5c1.5,1.6,2.2,3.6,2.2,5.9v8.4h-3.9V23.5c0-1.5-0.4-2.8-1.2-3.9c-0.8-1.1-1.9-1.6-3.2-1.6c-0.8,0-1.5,0.3-2.3,1
	c-0.7,0.7-1.1,1.6-1.1,2.7v12.1h-3.9V23.5c0-1-0.2-1.9-0.5-2.7c-0.3-0.8-0.9-1.5-1.5-2c-0.7-0.5-1.5-0.8-2.3-0.8
	c-0.3,0-0.6,0-0.9,0.1s-0.6,0.3-0.9,0.5s-0.6,0.4-0.8,0.7c-0.2,0.3-0.4,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3v12.1L104.1,33.8
	L104.1,33.8z M134.3,33.8V9.9l3.9-1.3v9.5c1.3-0.7,2.7-1.1,4.2-1.1c2.3,0,4.3,0.8,5.9,2.5c1.7,1.6,2.5,3.6,2.5,5.9s-0.8,4.3-2.5,5.9
	c-1.6,1.6-3.6,2.4-5.9,2.4c-1.5,0-2.9-0.4-4.2-1.1v1.1h-3.9V33.8z M138.2,23.5L138.2,23.5c0,1.1,0.1,2.1,0.3,3.1
	c0.5,2,1.3,3.6,2.3,4.8c1.1,1.2,2.1,1.6,3.2,1.3c1.1-0.3,1.8-1.3,2.2-2.9s0.4-3.4-0.1-5.4s-1.2-3.6-2.3-4.7
	c-1.1-1.2-2.1-1.6-3.2-1.3c-0.5,0.2-0.9,0.5-1.3,1c-0.4,0.5-0.7,1.1-0.9,1.8S138.2,22.6,138.2,23.5z M160.2,17.1
	c1.3,0.2,2.4,0.7,3.3,1.4c0.9,0.7,1.6,1.5,2,2.6c0.5,1,0.8,2.2,1.1,3.6c0.2,1.3,0.3,2.8,0.3,4.4c0,1.5,0,3.1-0.1,4.8h-3.9V33
	c-1,0.5-2.2,0.8-3.4,0.8c-2,0-3.6-0.6-5-1.8s-2.1-2.7-2.1-4.5c0-1.7,0.7-3.2,2.1-4.4c1.4-1.2,3.1-1.9,5-1.9c1.3,0,2.4,0.3,3.5,0.8
	c-0.5-2.9-1.4-4.4-3-4.5c-0.9-0.1-1.8,0.3-2.8,1.3c-0.9,0.9-1.5,1.9-1.9,2.9c-0.2-0.2-0.7-0.5-1.5-1c-0.7-0.5-1.2-0.9-1.5-1.1
	c1.1-0.9,2.4-1.6,3.7-2.1C157.5,17,158.9,16.8,160.2,17.1z M160.6,32c0.9-0.9,1.6-2.2,2.1-3.7c0.5-1.6,0.6-3,0.3-4.2s-0.8-2-1.7-2.2
	c-0.4-0.1-0.7-0.1-1.1,0c-0.4,0.1-0.7,0.3-1.1,0.6c-0.3,0.3-0.7,0.6-1,1s-0.6,0.9-0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.7
	c-0.5,1.5-0.6,2.9-0.3,4.2c0.3,1.2,0.9,1.9,1.7,2.1C158.7,33.3,159.6,32.9,160.6,32z M169.7,33.8V18.3l3.9-1.3v1.5
	c1.2-1,2.7-1.5,4.2-1.5c2.1,0,3.8,0.8,5.3,2.5c1.5,1.6,2.2,3.6,2.2,5.9v8.4h-3.9V23.5c0-1.5-0.4-2.8-1.2-3.9S178.3,18,177,18
	c-0.8,0-1.6,0.3-2.3,1s-1.1,1.6-1.1,2.7v12.1C173.6,33.8,169.7,33.8,169.7,33.8z M188.1,33.8V9.9l3.9-1.3v13.3
	c0-0.3,0.1-0.5,0.3-0.8c0.1-0.3,0.3-0.6,0.6-0.9s0.5-0.6,0.8-0.9s0.7-0.6,1.1-0.9c0.4-0.3,0.9-0.5,1.4-0.7s1.1-0.4,1.7-0.5
	c0.6-0.1,1.3-0.2,2-0.2v2.7c-0.7-0.3-1.4-0.4-2.1-0.5c-1.6-0.1-3,0.2-4.1,1.1s-1.7,2-1.7,3.3h3l6.7,10.2h-4.1l-5.5-8.4v8.4H188.1z"
            />
            <g>
              <path
                fill="#F09C22"
                d="M77.6,38.8h2.7l6.1,10.7l6.2-10.7H95V54h-2.4V39.9L86.8,50l-1.4,2.3l-7.3-12.6V54h-0.6L77.6,38.8L77.6,38.8
		z"
              />
              <path
                fill="#F09C22"
                d="M98.1,45.3c1-1,2.2-1.5,3.6-1.5s2.6,0.5,3.6,1.5s1.5,2.2,1.5,3.6c0,1.4-0.5,2.6-1.5,3.6s-2.2,1.5-3.6,1.5
		s-2.6-0.5-3.6-1.5s-1.5-2.2-1.5-3.6S97.1,46.3,98.1,45.3z M102.7,53.3c0.7-0.2,1.1-0.8,1.4-1.8s0.2-2.1,0-3.3
		c-0.3-1.2-0.8-2.2-1.4-2.9c-0.7-0.7-1.3-1-1.9-0.8c-0.7,0.2-1.1,0.8-1.4,1.8s-0.2,2.1,0.1,3.3c0.3,1.2,0.7,2.2,1.4,2.9
		C101.5,53.2,102.1,53.5,102.7,53.3z"
              />
              <path
                fill="#F09C22"
                d="M108.3,54V39.5l2.4-0.8v5.8c0.8-0.4,1.6-0.7,2.5-0.7c1.4,0,2.6,0.5,3.6,1.5s1.5,2.2,1.5,3.6
		c0,1.4-0.5,2.6-1.5,3.6s-2.2,1.5-3.6,1.5c-0.9,0-1.8-0.2-2.5-0.7V54H108.3z M110.6,47.8L110.6,47.8c0,0.7,0.1,1.3,0.2,1.9
		c0.3,1.2,0.8,2.2,1.4,2.9c0.7,0.7,1.3,1,1.9,0.8s1.1-0.8,1.3-1.8c0.3-1,0.2-2.1,0-3.3c-0.3-1.2-0.8-2.2-1.4-2.9
		c-0.7-0.7-1.3-1-1.9-0.8c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.7-0.5,1.1C110.7,46.7,110.6,47.2,110.6,47.8z"
              />
              <path
                fill="#F09C22"
                d="M120.8,40.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8c0,0.4-0.1,0.8-0.4,1
		c-0.3,0.3-0.6,0.4-1,0.4s-0.8-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.8,0.4-1.1C120.1,40.3,120.4,40.1,120.8,40.1z M119.6,54v-9.4
		l2.4-0.8V54H119.6z"
              />
              <path fill="#F09C22" d="M124,54V39.5l2.4-0.8V54H124z" />
              <path
                fill="#F09C22"
                d="M132.8,43.8c0.9,0,1.8,0.2,2.6,0.7s1.4,1.1,1.9,1.9s0.7,1.6,0.7,2.5h-7.6c0,0.2,0.1,0.5,0.2,0.7
		c0.3,1.2,0.7,2.2,1.4,2.9s1.3,1,1.9,0.8c0.7-0.2,1.1-0.8,1.4-1.8l1.2,1.1c-1,0.9-2.2,1.4-3.5,1.4c-1.4,0-2.6-0.5-3.6-1.5
		s-1.5-2.2-1.5-3.6c0-1.4,0.5-2.6,1.5-3.6S131.4,43.8,132.8,43.8z M130.3,48.3h4.9v-0.1c-0.3-1.2-0.7-2.2-1.4-2.9s-1.3-1-1.9-0.8
		c-0.5,0.2-0.9,0.6-1.2,1.3C130.4,46.6,130.3,47.4,130.3,48.3z"
              />
            </g>
          </>
        )}
      </g>
    </svg>
  );
};

export default BimbankLogo;
