import React from 'react';
import { connect } from 'react-redux';
import { setHeaderDrawerToggle } from '../../reducers/ThemeOptions';

const HeaderDrawer = () => {
  return <></>;
};

const mapStateToProps = state => ({
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  role: state.auth.role.value
});

const mapDispatchToProps = dispatch => ({
  setHeaderDrawerToggle: enable => dispatch(setHeaderDrawerToggle(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDrawer);
