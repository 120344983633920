export const ANONYMOUS = "ANONYMOUS";
export const EMPLOYE_AGENCE = "EMPLOYE_AGENCE";
export const RESPONSABLE_AGENCE = "RESPONSABLE_AGENCE";
export const AGENT_COMPENSATION = "AGENT_COMPENSATION";
export const CLIENT = "CLIENT";
export const VENDOR = "VENDOR";

// notification status
export const DEMANDE_PAIEMENT = "DEMANDE_PAIEMENT";
export const DEMANDE_RETRAIT = "DEMANDE_RETRAIT";
export const DEMANDE_COMPENSATION = "DEMANDE_COMPENSATION";
export const COMPENSATION = "COMPENSATION";
//export const RETRAIT = "RETRAIT";
export const PAIEMENT = "PAIEMENT";
export const ENVOI = "ENVOI";
export const RECHARGE = "RECHARGE";

// agences types status
export const mapColorAgence = {
  AGENCE_INTERN: "info",
  PARTNER_SILVER: "warning",
  PARTNER_GOLD: "success",
};

// transactions types status
export const TRANSFERT = "01";
export const RETRAIT = "02";
export const COMP_VERSEMENT = "03";
export const COMP_RETRAIT = "04";
export const CAGNOTE = "09";
export const RECOLTE = "10";
export const PAIEMENT_MASSE = "11";
export const CAGNOTE_ANNULE = "12";
export const PAIEMENT_FACTURE = "13";
export const PAIEMENT_CREDIT = "14";

export const RECHARGE_WALLET = "16";
export const RECHARGE_COMPTE = "17";

export const NONE = "NONE";
export const INF_3000 = "INF_3000";
export const SUP_3000 = "SUP_3000";

export const mapColorTypes = {
  "01": "primary",
  "02": "danger",
  "03": "success",
  "04": "danger",
  "05": "success",
  "06": "warning",
  "07": "info",
  "08": "success",
  "09": "warning",
  10: "success",
  11: "primary",
  12: "info",
  13: "warning",
  14: "warning",
  16: "success",
  17: "warning",
};

export const mapTypeNames = {
  "01": "TRANSFERT",
  "02": "RETRAIT",
  "03": "COMPENSATION VERSEMENT",
  "04": "COMPENSATION RETRAIT",
  "05": "RECHARGE",
  "06": "PAIEMENT",
  "07": "ENVOI",
  "08": "REMBOURSEMENT",
  "09": "CAGNOTTE",
  10: "RECOLTE",
  11: "PAIEMENT MASSE",
  12: "CAGNOTTE ANNULÉ",
  13: "PAIEMENT FACTURE",
  14: "PAIEMENT CREDIT",
  16: "RECHARGE WALLET",
  17: "RECHARGE COMPTE",
};

// transactions status
export const NOT_WITHDRAWED = "NOT_WITHDRAWED";
export const TO_VALIDATE = "TO_VALIDATE";
export const WITHDRAWED = "WITHDRAWED";
export const CANCELED = "CANCELED";
export const COMFIRMED = "COMFIRMED";

export const mapStatusNames = {
  NOT_WITHDRAWED: "NON RETIRÉ",
  TO_VALIDATE: "EN ATTENTE",
  WITHDRAWED: "RETIRÉ",
  CANCELED: "ANNULÉ",
  COMFIRMED: "CONFIRMÉ",
};

export const mapColorStatus = {
  NOT_WITHDRAWED: "warning",
  WITHDRAWED: "success",
  CANCELED: "danger",
  TO_VALIDATE: "info",
  COMFIRMED: "success",
};

export const mapRoleName = {
  VENDOR: "Commerçant",
  RESPONSABLE_AGENCE: "Responsable agence",
  AGENT_COMPENSATION: "Agent de compensation",
  EMPLOYE_AGENCE: "Employé agence",
};

export const mapNotificationStatusNames = {
  PAIEMENT: "Paiement",
  RETRAIT: "Retrait",
  RECHARGE: "Recharge",
  ENVOI: "Envoi",
  DEMANDE_PAIEMENT: "Demande de paiement",
  DEMANDE_RETRAIT: "Demande de retrait",
  DEMANDE_COMPENSATION: "Demande de compensation",
};

// Filter options

export const agenceTransactionsStatusFilterOptions = [
  { value: NOT_WITHDRAWED, label: "NON RETIRÉ" },
  { value: TO_VALIDATE, label: "EN ATTENTE" },
  { value: WITHDRAWED, label: "RETIRÉ" },
  { value: CANCELED, label: "ANNULÉ" },
  { value: COMFIRMED, label: "CONFIRMÉ" },
];
export const agenceTransactionsTypeFilterOptions = [
  { value: TRANSFERT, label: "TRANSFERT" },
  { value: RETRAIT, label: "RETRAIT" },
  { value: COMP_VERSEMENT, label: "COMPENSATION VERSEMENT" },
  { value: COMP_RETRAIT, label: "COMPENSATION RETRAIT" },
];

// Search options

export const searchOptions = [
  ["code_transaction"],
  ["date"],
  ["transaction", "montant"],
];

// Date options

export const DATE_FORMAT = "dd-MM-yyyy";
export const START_TIME = " 00:00:00";
export const END_TIME = " 23:59:59";

// Facturiers
// list it when sysAdmin regiter a new facturier
export const SOMELEC = "SOMELEC";
export const SNDE = "SNDE";
export const WIFI_MOOV_MAURITEL = "WIFI MOOV MAURITEL";

// services
export const Electricte = "Électricité";
export const Eau = "Eau";
export const Internet = "Internet";
export const Chaines = "Chaines";

// Limits

export const MIN_AMOUNT = 10;
export const MAX_AMOUNT_INF_3000 = 30000;
export const MIN_AMOUNT_SUP_3000 = 30001;
export const MAX_AMOUNT = 100000;
export const MAX_AMOUNT_COMP = 1000000;

export const MIN_TIME_LIMIT = 1;
export const MAX_TIME_LIMIT = 90;

export const MIN_PHONE_NUMBER = 20000000;
export const MAX_PHONE_NUMBER = 99999999;

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 30;

export const MIN_CVV_LENGTH = 0;
export const MAX_CVV_LENGTH = 4;

// Input Error Messages

export const amountRequiredMsg = "Montant est obligatoire !";
export const transactionTypeRequirdMsg = "Type de transaction est obligatoire!";
export const agencyRequiredMsg = "Agence est obligatoire!";
export const phoneNumberRequired = "Numéro de téléphone est obligatoire !";
export const usernameRequired = "Nom d'utilisateur est obligatoire !";
export const firstnameRequired = "Prénom est obligatoire !";
export const lastnameRequired = "Nom est obligatoire !";

export const fullnameRequired = "Nom complet est obligatoire !";
export const creditCardTypeRequired = "Type de carte est obligatoire !";
export const cardNumberRequired = "Numéro de la carte est obligatoire !";
export const monthRequired = "Mois est obligatoire !";
export const yearRequired = "Année est obligatoire !";
export const cvvRequired = "Code CVV est obligatoire !";

export const passwordRequired = "Mot de passe est obligatoire !";
export const prevPasswordRequired =
  "Veuillez renseigner votre ancien mot de passe !";
export const newPasswordRequired =
  "Veuillez renseigner votre nouveau mot de passe !";
export const cardRequired = "La carte est obligatoire !";
export const facturierRequired = "La facturier est obligatoire !";
export const chooseServiceRequired = "Veuillez choisir un service !";
export const codeOrNumberRequired =
  "Code de paiement ou numéro de commerçant est obligatoire !";
export const codeOrFactureRequired = "Code de paiement est obligatoire !";
export const transactionNbrRequired = "Numéro de transaction est obligatoire !";
export const referenceRequired = "La référence est obligatoire !";
export const expediteurRequired = "Expéditeur est obligatoire !";
export const destinataireRequired = "Destinataire est obligatoire !";
export const agencyDestinationRequired = "Agence destination est obligatoire !";
export const timeLimitRequired = "Le delai est obligatoire !";
export const cagnotteNameRequired = "Nom de la cagnote est obligatoire !";
export const cagnotteIdRequired =
  "L'identifiant de la cagnote est obligatoire !";
export const grpPayNameRequired = "Nom du groupe de payement est obligatoire !";
export const payMotifRequired = "Motif du paiement est obligatoire !";
export const cagnotteDescRequired =
  "La description de la cagnotte est obligatoire !";

export const minAmountMsg = "Montant doit être plus 10 MRU !";
export const minAmountSup3000Msg = "Montant doit être plus 30000 MRU !";
export const maxAmountMsg = "Montant ne peut dépasser 1000000 MRU !";
export const maxAmountCompensationMsg =
  "Montant ne peut dépasser 1000000 MRU !";
export const maxAmountInf3000Msg = "Montant ne peut dépasser 30000 MRU !";

export const maxCharLimitExceeded = "Nombre de caractères limite dépassé !";
export const minCharLimit = "Minimum 3 caractères !";
export const shortPasswordMsg =
  "Votre mot de passe est trop court , minimum 8 lettres !";
export const confirmPasswordMsg = "Veuillez confirmer votre mot de passe !";
export const identicalPasswordMsg = "Le mot de passe doit être identique !";

export const phoneNumberInvalid = "Numéro de téléphone invalide !";
export const emailInvalid = "Adresse email invalide !";
export const cvvInvalid = "Code CVV invalide  !";
export const cardNumberInvalid = "Numéro de la carte invalide  !";
export const yearInvalid = "Année invalide  !";

export const minTimeLimitMsg = `Le delai minimun est de ${MIN_TIME_LIMIT} jour${
  MIN_TIME_LIMIT > 1 ? "s" : ""
} !`;
export const maxTimeLimitMsg = `Le delai maximum est de ${MAX_TIME_LIMIT} jours !`;

// Alert Messages

export const rimcashClientMsg =
  "Nous vous conseillons d'utiliser l'application mobile pour un envoi venant d'un client bimbank Mobile";

export const tokenExpiredMsg =
  "Le délai de votre session a expiré, veuillez vous reconnecté SVP ... !";

export const tokenExpiredWillLogoutMsg =
  "Le délai de votre session a expiré, vous allez être déconnecté dans quelque secondes ...";

export const yourTokenHasExpiredMsg = "Votre token a déjà expirer !";
