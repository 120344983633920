import React from 'react';
import { connect } from 'react-redux';

const SidebarFooter = () => {
  return <div className="app-sidebar--footer" />;
};

const mapStateToProps = state => ({
  role: state.auth.role.value
});

export default connect(mapStateToProps)(SidebarFooter);
