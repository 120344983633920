import { toast } from "react-toastify";
import { AUTH_ERROR, CLEAN_SESSION, TOKEN_EXPIRED } from "../actions/types";
import alertMessage from "./alertMessage";
import { tokenExpiredMsg, tokenExpiredWillLogoutMsg } from "./Constants";

export const showAlert = (type, text) => {
  switch (type) {
    case "info":
      toast.info(alertMessage("Info", text));
      break;
    case "warning":
      toast.warn(alertMessage("Attention", text));
      break;
    case "danger":
      toast.error(alertMessage("Erreur", text));
      break;
    case "success":
      toast.success(alertMessage("Succès", text));
      break;

    default:
      toast(alertMessage("Info", text));
      break;
  }
};

export const expiredToken = (dispatch, tokenInvalid = false) => {
  if (tokenInvalid === false) {
    dispatch({
      type: TOKEN_EXPIRED,
    });
    setTimeout(() => {
      showAlert("warning", tokenExpiredWillLogoutMsg);
      setTimeout(() => {
        dispatch({
          type: AUTH_ERROR,
        });
        dispatch({
          type: CLEAN_SESSION,
        });
      }, 2500);
    }, 2000);
  }
};

export const expiredTokenWarning = () => {
  setTimeout(() => {
    showAlert("warning", tokenExpiredMsg);
  }, 2000);
};
