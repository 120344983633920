import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, UncontrolledTooltip } from "reactstrap";
import projectLogo from "../../assets/images/logos/rimcash-logo-neg.png";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";

const SidebarCollapsed = props => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarShadow,
    sidebarStyle,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props;

  return (
    <>
      <div
        className={clsx(
          "app-sidebar app-sidebar--collapsed app-sidebar--mini",
          sidebarStyle,
          { "app-sidebar--shadow": sidebarShadow }
        )}>
        <div className="app-sidebar--header">
          <div className="app-sidebar-logo">
            <Link
              to="/Dashboard"
              title="Bamburgh React Admin Dashboard with Reactstrap PRO"
              className="app-sidebar-logo">
              <div className="app-sidebar-logo--icon">
                <img
                  alt="Bamburgh React Admin Dashboard with Reactstrap PRO"
                  src={projectLogo}
                />
              </div>
            </Link>
          </div>
        </div>

        <div className="app-sidebar--content">
          <PerfectScrollbar>
            <div className="text-center mb-2">
              <Button
                tag={Link}
                color="warning"
                className="m-1 p-0 d-inline-block text-center font-size-lg d-40 rounded"
                to="/Dashboard"
                id="CollapsedSidebarTooltip131">
                <FontAwesomeIcon icon={["fas", "arrow-left"]} />
              </Button>
              <UncontrolledTooltip
                popperClassName="tooltip-secondary text-nowrap"
                placement="right"
                target="CollapsedSidebarTooltip131"
                container=".app-sidebar--content"
                boundariesElement="window">
                Dashboard
              </UncontrolledTooltip>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx("app-sidebar-overlay", {
          "is-active": sidebarToggleMobile,
        })}
      />
    </>
  );
};

const mapStateToProps = state => ({
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarStyle: state.ThemeOptions.sidebarStyle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapsed);
