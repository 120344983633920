import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "qrcode";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Badge, Button, Modal } from "reactstrap";
import VendorQrCodePDF from "../../app-components/transaction/VendorQrCodePDF";
import av1 from "../../assets/images/avatars/av1.png";
import useGeneratePDF from "../../hooks/useGeneratePDF";
import { VENDOR } from "../../utils/Constants";

const SidebarUserbox = props => {
  const [qrCodeBase64, setQrCodeBase64] = useState("");
  const [modalQrCode, setModalQrCode] = useState(false);

  const username = props.user
    ? props.user.first_name + " " + props.user.last_name
    : "";

  const [pdfLoading, generatePdfDocument] = useGeneratePDF(
    <VendorQrCodePDF
      imgSrc={qrCodeBase64}
      vendorCode={props.user?.myId}
      vendorName={username}
    />,
    `QrCode-${username}-${props.user?.myId}`
  );

  const generateQrCode = txt => {
    if (typeof txt !== "string") return null;

    const options = { type: "image/png", scale: 15, margin: 1.5 };

    QRCode.toDataURL(txt, options, (err, str) => {
      if (err) {
        throw err;
      }

      setQrCodeBase64(str);
      setModalQrCode(!modalQrCode);
    });
  };

  const profil = (
    <div className="app-sidebar--userbox">
      <div className="avatar-icon-wrapper avatar-icon-md">
        <Badge color="success" className="badge-circle">
          Online
        </Badge>
        <div className="avatar-icon rounded-circle">
          <img alt="..." src={av1} />
        </div>
      </div>
      <div className="mt-3 mb-0 userbox-details">
        {props.user && props.user.first_name + " " + props.user.last_name}
        <div className="text-white py-1">
          <Badge color="secondary">{props.role && props.role.label}</Badge>
          {props.user?.premium && (
            <Badge color="primary" className="mx-1">
              Pro
            </Badge>
          )}
        </div>
        <small className="d-block text-white-20">
          {props.user && props.user.tel}
        </small>
        {props.role?.value === VENDOR && (
          <Button
            block
            className="my-2 font-weight-bold text-white"
            size="sm"
            color="primary"
            onClick={() => generateQrCode(props.user?.myId)}>
            QrCode
          </Button>
        )}
      </div>
    </div>
  );
  return (
    <>
      {profil}
      <Modal
        zIndex={2000}
        centered
        size="sm"
        isOpen={modalQrCode}
        toggle={() => setModalQrCode(!modalQrCode)}
        contentClassName="border-0">
        <div className="p-2">
          {qrCodeBase64 && (
            <>
              <img
                src={qrCodeBase64}
                alt="QrCode du commercant"
                className="w-100"
              />
              <p className="text-center font-size-xl mb-2">Code commerçant</p>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="d-inline-block rounded-sm text-white font-weight-bold bg-primary mr-3 px-4 py-1 font-size-xl">
                  {props.user?.myId}
                </span>
                <Button
                  color="secondary"
                  title="Télécharger sous format PDF"
                  disabled={pdfLoading}
                  onClick={generatePdfDocument}
                  className="rounded-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon={["fas", pdfLoading ? "spinner" : "file-download"]}
                  />
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  role: state.auth.role,
});

export default connect(mapStateToProps, {})(SidebarUserbox);
