/**
 *
 * @param {string} heading Heading of the Toast
 * @param {string} body The body of the Toast
 * @returns {HTMLElement}
 */

const alertMessage = (heading, body) => (
  <div className="d-flex flex-column">
    <div className="font-weight-bold text-black">{heading + " !"}</div>
    <div>{body}</div>
  </div>
);

export default alertMessage;
