import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { logout } from "../../actions/auth";
import avatar7 from "../../assets/images/avatars/av6.png";
import { mapRoleName } from "../../utils/Constants";

const HeaderUserbox = ({ user, logout }) => {
  if (!user) return null;

  const { first_name, last_name, role } = user;
  return (
    <UncontrolledDropdown className="position-relative ml-2">
      <DropdownToggle
        color="link"
        style={{ textDecoration: "none" }}
        className="d-flex align-items-center text-left p-0">
        <div className="avatar-icon-wrapper">
          <Badge color="success" className="badge-circle p-top-a">
            En ligne
          </Badge>
          <div className="avatar-icon rounded">
            <img src={avatar7} alt="..." />
          </div>
        </div>
        <div className="d-none d-xl-block pl-2">
          <h6 className="font-weight-bold m-0">
            {first_name} {last_name}
          </h6>
          <p className="text-black-50 m-0">{mapRoleName[role]}</p>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={["fas", "angle-down"]} className="opacity-5" />
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-lg">
        <Link to="/Profil" style={{ textDecoration: "none" }}>
          <DropdownItem style={{ outline: 0 }}>
            <FontAwesomeIcon icon={["fas", "user"]} className="text-dark" />
            <span className="px-2">Mon Profil</span>
          </DropdownItem>
        </Link>
        <DropdownItem divider />
        <DropdownItem onClick={logout} style={{ outline: 0 }}>
          <FontAwesomeIcon
            icon={["fas", "sign-out-alt"]}
            className="text-danger"
          />
          <span className="px-2">Déconnexion</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapStateToProps = state => ({ user: state.auth.user });

export default connect(mapStateToProps, { logout })(HeaderUserbox);
