import axios from "axios";
import { expiredToken, showAlert } from "../utils/alerts";
import { CLIENT } from "../utils/Constants";
import {
  AUTH_ERROR,
  AUTH_LOADING,
  CHANGE_ROLE,
  CLEAN_SESSION,
  HOST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
} from "./types";

export const changeRole = role => ({
  type: CHANGE_ROLE,
  payload: role,
});

export const login = (data, _, setSubmitting) => dispatch => {
  dispatch({
    type: AUTH_LOADING,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 30000,
  };
  const body = { ...data };
  axios
    .post(HOST + "api/login/", body, config)
    .then(res => {
      //setTimeout(() => {
      if (res.data?.role === CLIENT) {
        showAlert(
          "warning",
          "Le client bimbank Mobile ne peut se connecter qu'à partir de l'application mobile!"
        );
        dispatch({
          type: LOGIN_FAIL,
        });
        setSubmitting(false);
        return;
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      //setSubmitting(false);
      //resetForm();
      showAlert("success", "Login Success!");
      //}, 2000);
    })
    .catch(err => {
      dispatch({
        type: LOGIN_FAIL,
      });
      if (err.response && err.response.status === 401) {
        showAlert(
          "warning",
          "Aucun compte actif trouvé avec les identifiants donnés !"
        );
      } else {
        showAlert("danger", "Login Echec!");
      }
      setSubmitting(false);
    });
};

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  const access = getState().auth.access;
  //const refresh = getState().auth.refresh;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 30000,
  };
  let url = "";
  if (access) {
    config.headers["Authorization"] = `JWT ${access}`;
    const tokenParts = JSON.parse(atob(access.split(".")[1]));
    url = `api/user/auth-user/get/${tokenParts.userId}/`;
    axios
      .get(HOST + url, config)
      .then(res => {
        //setTimeout(() => {
        if (res.data?.role === CLIENT) {
          showAlert(
            "warning",
            "Le client bimbank Mobile ne peut se connecter qu'à partir de l'application mobile!"
          );
          dispatch({
            type: AUTH_ERROR,
          });
          dispatch({
            type: CLEAN_SESSION,
          });
          return;
        }
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
        //}, 25000);
      })
      .catch(err => {
        dispatch({
          type: AUTH_ERROR,
        });
        dispatch({
          type: CLEAN_SESSION,
        });
      });
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
    dispatch({
      type: CLEAN_SESSION,
    });
  }
};

export const logout = () => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  const refresh = getState().auth.refresh;
  const access = getState().auth.access;
  const body = { refresh };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 30000,
  };
  config.headers["Authorization"] = `JWT ${access}`;
  axios
    .post(HOST + "api/logout/", body, config)
    .then(() => {
      //setTimeout(() => {
      dispatch({
        type: LOGOUT,
      });
      dispatch({
        type: CLEAN_SESSION,
      });
      //}, 25000);
    })
    .catch(err => {
      dispatch({
        type: AUTH_ERROR,
      });
      dispatch({
        type: CLEAN_SESSION,
      });
    });
};

export const updatePassword =
  (id, body, setSubmitting, showAlert) => (dispatch, getState) => {
    //dispatch({ type: AUTH_LOADING });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 30000,
    };

    const access = getState().auth.access;
    if (access) {
      config.headers["Authorization"] = `JWT ${access}`;
    }
    axios
      .put(HOST + `api/user/password/update/${id}/`, body, config)
      .then(res => {
        if (res) {
          showAlert("success", res.data.msg);
          setTimeout(() => {
            showAlert(
              "warning",
              "Vous allez être déconnecté dans quelque secondes ..."
            );
            setTimeout(() => {
              dispatch({
                type: LOGOUT,
              });
              dispatch({
                type: CLEAN_SESSION,
              });
            }, 2500);
          }, 2000);
        }
        setSubmitting(false);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          expiredToken(dispatch, getState().auth.tokenExpired);
        } else if (err.response && err.response.status === 400) {
          showAlert("warning", err.response.data?.msg);
        } else {
          showAlert(
            "danger",
            "Erreur de modification du mot de passe utilisateur !"
          );
        }
        setSubmitting(false);
      });
  };

/*
export const clearSession = (showAlert) => (dispatch, getState) => {
  const tokenInvalid = getState().auth.tokenExpired;
  if (!tokenInvalid) {
    dispatch({
      type: TOKEN_EXPIRED,
    });
    setTimeout(() => {
      showAlert(
        "warning",
        "le délai de votre session a expiré , vous allez être déconnecté dans quelque secondes ...",
      );
      setTimeout(() => {
        dispatch({
          type: AUTH_ERROR,
        });
        dispatch({
          type: CLEAN_SESSION,
        });
      }, 2500);
    }, 2000);
  }
};*/
