import { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { login } from "../../actions/auth";
import illustration1 from "../../assets/images/illustrations/authentication.svg";
import BimbankLogo from "../../components/common/BimbankLogo";
import { SuspenseLoading } from "../../routers/pageTransition";
import FormLogin from "./FormLogin";

function Login(props) {
  const [formSubmiting, setFormSubmiting] = useState(false);
  const handleFormLoading = () => setFormSubmiting(true);

  if (props.auth.isLoading && !formSubmiting) return <SuspenseLoading />;

  if (!props.auth.isAuthenticated) {
    return (
      <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container>
                    <Row>
                      <Col className="d-flex justify-content-center mb-3 mb-sm-4">
                        <div className="login-logo">
                          <BimbankLogo full={true} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" className="d-flex align-items-center">
                        <div className="divider-v d-none d-lg-block divider-v-md" />
                        <div className="w-100 pr-0 pr-lg-5">
                          <div className="text-black mt-3">
                            <span className="text-center">
                              <h1 className="display-3 mb-4">Se connecter</h1>
                            </span>

                            <div>
                              <FormLogin
                                handleFormLoading={handleFormLoading}
                              />
                            </div>
                            {/* <div className="text-center pt-4 text-black-50">
                              Vous n'avez pas de compte ?
                              <NavLink to="/Register">
                                <span className="pl-2">
                                  Inscrivez-vous maintenant -&gt;
                                </span>
                              </NavLink>
                            </div> */}
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg="6"
                        className="d-none d-lg-flex align-items-center">
                        <img
                          alt="..."
                          className="w-100 mx-auto d-block img-fluid"
                          src={illustration1}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Redirect to="/Dashboard" />;
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(Login);
