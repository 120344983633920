import { connect } from "react-redux";
import { ANONYMOUS, CLIENT } from "../utils/Constants";
import DefaultRoutes from "./DefaultRoutes";
import Routes from "./Routes";

function MainRouter({ role }) {
  if (role.value === ANONYMOUS) {
    return <DefaultRoutes />;
  }

  if (role.value === CLIENT) {
    return <h3>Not allowed</h3>;
  }

  return <Routes />;
  // return (
  //   <div>
  //     {role.value === ANONYMOUS && <DefaultRoutes />}
  //     {role.value !== ANONYMOUS && (
  //       <>{user?.premium ? <PremiumRoutes /> : <Routes />}</>
  //     )}
  //   </div>
  // );
}

const mapStateToProps = state => ({
  role: state.auth.role,
});

export default connect(mapStateToProps)(MainRouter);
