import clsx from "clsx";
import { connect } from "react-redux";
import { SidebarHeader, SidebarMenu } from "../../layout-components";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";

const Sidebar = ({
  sidebarStyle,
  sidebarShadow,
  sidebarToggleMobile,
  setSidebarToggleMobile,
}) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx("app-sidebar", sidebarStyle, {
          "app-sidebar--shadow": sidebarShadow,
        })}>
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx("app-sidebar-overlay", {
          "is-active": sidebarToggleMobile,
        })}
      />
    </>
  );
};

const mapStateToProps = state => ({
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarStyle: state.ThemeOptions.sidebarStyle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
