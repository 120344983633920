// Done!!
import { RiseLoader } from "react-spinners";

export const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 1.01,
  },
};

export const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.4,
};

export const SuspenseLoading = () => (
  <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
    <div className="d-flex align-items-center flex-column px-4 pb-2">
      <RiseLoader color={"#F09C22"} loading={true} />
    </div>
    <div className="text-black font-size-xl text-center pt-4">
      Lancement de bimbank Mobile Web ...
    </div>
  </div>
);
