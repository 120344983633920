import axios from "axios";
import { Field, Form, withFormik } from "formik";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Label } from "reactstrap";
import * as Yup from "yup";
import { HOST } from "../../actions/types";
import FieldErrorMessage from "../../components/common/FieldErrorMessage";
import SubmitButton from "../../components/common/SubmitButton";
import { showAlert } from "../../utils/alerts";
import {
  emailInvalid,
  firstnameRequired,
  maxCharLimitExceeded,
  MAX_PHONE_NUMBER,
  minCharLimit,
  MIN_PHONE_NUMBER,
  passwordRequired,
  VENDOR,
} from "../../utils/Constants";
import "../dashboard/formik-demo.css";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
};

const RequiredLabel = () => <span className="text-danger">*</span>;

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    username: Yup.number()
      .required("Le nom d'utilisateur doit être un numéro de téléphone valide")
      .min(
        MIN_PHONE_NUMBER,
        "Le nom d'utilisateur doit être un numéro de téléphone valide"
      )
      .max(
        MAX_PHONE_NUMBER,
        "Le nom d'utilisateur doit être un numéro de téléphone valide"
      ),
    first_name: Yup.string()
      .required(firstnameRequired)
      .min(3, minCharLimit)
      .max(40, maxCharLimitExceeded),
    email: Yup.string().email(emailInvalid).max(40, maxCharLimitExceeded),
    adresse: Yup.string().min(3, minCharLimit).max(50, maxCharLimitExceeded),
    password: Yup.string()
      .required(passwordRequired)
      .max(40, maxCharLimitExceeded),
    passwordConfirmation: Yup.string()
      .required("Mot de passe confirmation est obligatoire !")
      .max(40, maxCharLimitExceeded)
      .oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe doivent correspondre !"
      ),
  }),
  mapPropsToValues: () => ({
    username: "",
    first_name: "",
    email: "",
    adresse: "",
    password: "",
    passwordConfirmation: "",
    // token: null,
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    // if (!values.token) {
    //   showAlert("warning", "Valider le Captcha SVP ...");
    //   setSubmitting(false);
    // } else {
    const data = {
      username: values.username,
      tel: values.username,
      first_name: values.first_name,
      role: VENDOR,
      password: values.password,
      adresse: values.adresse,
      email: values.email,
    };

    try {
      const { status } = await axios.post(
        HOST + "api/user/vendor/register/",
        data,
        axiosConfig
      );

      if (status === 200 || status === 201) {
        showAlert("success", "Commerçant créé avec succès !");
        setTimeout(() => {
          props.historyPush("/Login");
        }, 1000);
      }
    } catch (err) {
      console.error("err", JSON.stringify(err, null, 2));
      if (!err.response) {
        showAlert("danger", "Erreur lors de la création de commerçant !");
      } else if (err.response?.status === 400 && err.response.data?.msg) {
        showAlert("warning", err.response.data?.msg);
      }
    }

    setSubmitting(false);
    // }
  },
  displayName: "MyForm",
});

const MyForm = props => {
  const { touched, errors, handleSubmit, isSubmitting } = props;

  return (
    <Form onSubmit={handleSubmit} autoComplete="new-password">
      <div className="mt-3">
        <Label htmlFor="username">
          Nom d'utilisateur (Numéro de téléphone) <RequiredLabel />
        </Label>
        <Field
          name="username"
          type="number"
          placeholder="Nom utilisateur de commerçant ..."
        />

        <FieldErrorMessage show={errors.username && touched.username}>
          {errors.username}
        </FieldErrorMessage>
      </div>

      <div className="mt-3">
        <Label htmlFor="first_name">
          Nom <RequiredLabel />
        </Label>
        <Field
          name="first_name"
          type="text"
          placeholder="Entrer votre prénom ..."
        />

        <FieldErrorMessage show={errors.first_name && touched.first_name}>
          {errors.first_name}
        </FieldErrorMessage>
      </div>

      <div className="mt-3">
        <Label htmlFor="password">
          Mot de passe <RequiredLabel />
        </Label>
        <Field
          name="password"
          type="password"
          placeholder="Entrer votre mot de passe ..."
          autoomplete="new-password"
        />

        <FieldErrorMessage show={errors.password && touched.password}>
          {errors.password}
        </FieldErrorMessage>
      </div>

      <div className="mt-3">
        <Label htmlFor="passwordConfirmation">
          Confirmation mot de passe <RequiredLabel />
        </Label>
        <Field
          name="passwordConfirmation"
          type="password"
          placeholder="Confirmer votre mot de passe ..."
        />

        <FieldErrorMessage
          show={errors.passwordConfirmation && touched.passwordConfirmation}>
          {errors.passwordConfirmation}
        </FieldErrorMessage>
      </div>

      <div className="mt-3">
        <Label htmlFor="email">Email</Label>
        <Field name="email" type="text" placeholder="Entrer votre email ..." />

        <FieldErrorMessage show={errors.email && touched.email}>
          {errors.email}
        </FieldErrorMessage>
      </div>

      <div className="mt-3">
        <Label htmlFor="adresse">Adresse</Label>
        <Field
          name="adresse"
          type="text"
          placeholder="Entrer votre adresse ..."
        />

        <FieldErrorMessage show={errors.adresse && touched.adresse}>
          {errors.adresse}
        </FieldErrorMessage>
      </div>

      <p className="text-right mt-3 font-size-sm">
        ( <RequiredLabel /> ) Champs obligatoire
      </p>

      <div className="">
        <SubmitButton className="mb-3" block loading={isSubmitting}>
          S'inscrire
        </SubmitButton>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.isLoading,
});
const MyEnhancedForm = connect(mapStateToProps)(formikEnhancer(MyForm));

const FormRegisterVendor = props => {
  const { push } = useHistory();
  return (
    <MyEnhancedForm
      handleFormLoading={props.handleFormLoading}
      historyPush={push}
    />
  );
};

export default FormRegisterVendor;
