import { Field, Form, withFormik } from "formik";
// import ReCaptchaV2 from "react-google-recaptcha";
import { connect } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { login } from "../../actions/auth";
import FieldErrorMessage from "../../components/common/FieldErrorMessage";
import SubmitButton from "../../components/common/SubmitButton";
import { showAlert } from "../../utils/alerts";
import {
  maxCharLimitExceeded,
  passwordRequired,
  phoneNumberRequired,
} from "../../utils/Constants";
import "../dashboard/formik-demo.css";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    username: Yup.string()
      .required(phoneNumberRequired)
      .max(30, maxCharLimitExceeded),
    password: Yup.string()
      .required(passwordRequired)
      .max(30, maxCharLimitExceeded),
  }),
  mapPropsToValues: () => ({
    username: "",
    password: "",
    // token: null,
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    // if (!values.token) {
    //   showAlert("warning", "Valider le Captcha SVP ...");
    //   setSubmitting(false);
    // } else {
    props.handleFormLoading();
    const payload = {
      ...values,
    };
    props.login(payload, resetForm, setSubmitting, showAlert);
    // }
  },
  displayName: "MyForm",
});

const MyForm = props => {
  const { touched, errors, handleSubmit, isSubmitting } = props;

  // const handleToken = token => {
  //   values.token = token;
  // };

  // const handleExpire = () => {
  //   values.token = null;
  // };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xl="12" style={{ margin: "12px 0" }}>
          <Label htmlFor="username">Téléphone</Label>
          <Field
            name="username"
            type="text"
            placeholder="Numéro de téléphone"
          />

          <FieldErrorMessage show={errors.username && touched.username}>
            {errors.username}
          </FieldErrorMessage>
        </Col>
      </Row>
      <Row>
        <Col xl="12" style={{ margin: "12px 0" }}>
          <Label htmlFor="password">Mot de passe</Label>
          <Field name="password" type="password" placeholder="Mot de passe" />

          <FieldErrorMessage show={errors.password && touched.password}>
            {errors.password}
          </FieldErrorMessage>
        </Col>
      </Row>
      {/* <Row>
        <Col xl="12" style={{ margin: "12px 0" }}>
          <ReCaptchaV2
            sitekey={process.env.REACT_APP_SITE_KEY ?? "qsdlvjjn"}
            onChange={handleToken}
            onExpired={handleExpire}
            lang="fr"
          />
        </Col>
      </Row> */}
      <SubmitButton block loading={isSubmitting}>
        Connexion
      </SubmitButton>
    </Form>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.isLoading,
});
const MyEnhancedForm = connect(mapStateToProps, { login })(
  formikEnhancer(MyForm)
);

const FormLogin = props => (
  <MyEnhancedForm handleFormLoading={props.handleFormLoading} />
);
export default FormLogin;
