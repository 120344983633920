import { AnimatePresence, motion } from "framer-motion";
import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Login from "../app-components/auth/Login";
import Page404 from "../app-components/pageError/Page404";
import { LeftSidebar, PresentationLayout } from "../layout-blueprints";
import {
  AGENT_COMPENSATION,
  CLIENT,
  EMPLOYE_AGENCE,
  RESPONSABLE_AGENCE,
  VENDOR,
} from "../utils/Constants";
// import { AGENT_COMPENSATION } from "../utils/Constants";
import PrivateRoute from "../utils/PrivateRoute";
import {
  pageTransition,
  pageVariants,
  SuspenseLoading,
} from "./pageTransition";

const importDashboardByRole = role => {
  let component = function DefaultComponent() {
    return <h3>Accès non autorisé</h3>;
  };
  if (role === RESPONSABLE_AGENCE) {
    component = lazy(() =>
      import("../app-components/dashboard/DashboardResponsable")
    );
  } else if (role === EMPLOYE_AGENCE) {
    component = lazy(() =>
      import("../app-components/dashboard/DashboardEmploye")
    );
  } else if (role === CLIENT) {
    component = lazy(() =>
      import("../app-components/dashboard/DashboardClient")
    );
  } else if (role === VENDOR) {
    component = lazy(() =>
      import("../app-components/dashboard/DashboardVendor")
    );
  } else if (role === AGENT_COMPENSATION) {
    component = lazy(() =>
      import("../app-components/dashboard/DashboardCompensation")
    );
  }

  return component;
};

// const importTransactionByRole = role => {
//   let component = function DefaultComponent() {
//     return <h3>Accès non autorisé</h3>;
//   };
//   if (role === RESPONSABLE_AGENCE || role === EMPLOYE_AGENCE) {
//     component = lazy(() => import("../app-components/transaction/Transaction"));
//   } else if (role === CLIENT) {
//     component = lazy(() =>
//       import("../app-components/transaction/TransactionClient")
//     );
//   } else if (role === VENDOR) {
//     component = lazy(() =>
//       import("../app-components/transaction/TransactionVendor")
//     );
//   } else if (role === AGENT_COMPENSATION) {
//     component = lazy(() =>
//       import("../app-components/transaction/TransactionCompensation")
//     );
//   }
//   return component;
// };

const TransactionComponent = lazy(() =>
  import("../app-components/dashboard/TransactionsHistoryUpdated")
);

const Agence = lazy(() => import("../app-components/agence/Agence"));
const Employe = lazy(() => import("../app-components/employe/Employe"));
const Recharge = lazy(() => import("../app-components/recharge/FormBanquaire"));
// const Compensation = lazy(() =>
//   import("../app-components/compensation/Compensation")
// );
const Profil = lazy(() => import("../app-components/profil/Profil"));
const Notification = lazy(() =>
  import("../app-components/notification/Notification")
);
const Contact = lazy(() => import("../app-components/contact/Contact"));
const Cagnote = lazy(() => import("../app-components/cagnote/Cagnote"));

const Routes = props => {
  const location = useLocation();
  const Dashboard = importDashboardByRole(props.role);
  // const Transaction = importTransactionByRole(props.role);

  const RoutesSidebar = [
    {
      component: Dashboard,
      link: "/Dashboard",
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      component: Agence,
      link: "/Agence",
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      component: Employe,
      link: "/Employe",
      roles: [RESPONSABLE_AGENCE],
    },
    {
      component: TransactionComponent,
      link: "/Transaction",
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        //AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      component: Recharge,
      link: "/RechargeBanquaire",
      roles: [CLIENT],
    },
    {
      component: TransactionComponent,
      link: "/Compensation",
      roles: [RESPONSABLE_AGENCE, AGENT_COMPENSATION],
    },
    {
      component: Notification,
      link: "/Notification",
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      component: Contact,
      link: "/Contact",
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      component: Cagnote,
      link: "/Cagnote",
      roles: [CLIENT],
    },
  ];

  const PathSidebar = RoutesSidebar.filter(item =>
    item.roles.includes(props.role)
  ).map(item => {
    return item.link; //
  });

  return (
    <AnimatePresence>
      <Switch>
        <Redirect exact from="/" to="/Login" />

        <Route path={["/Login"]}>
          <PresentationLayout>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/Login" component={Login} />
              </motion.div>
            </Switch>
          </PresentationLayout>
        </Route>

        <Suspense fallback={<SuspenseLoading />}>
          <Route path={PathSidebar}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  {RoutesSidebar.filter(item =>
                    item.roles.includes(props.role)
                  ).map((item, index) => {
                    return (
                      <PrivateRoute
                        key={index}
                        path={item.link}
                        component={item.component}
                      />
                    );
                  })}
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>
          <Route path={["/Profil"]}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <PrivateRoute path="/Profil" component={Profil} />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>
        </Suspense>

        <Route component={Page404} />
      </Switch>
    </AnimatePresence>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role.value,
});

export default connect(mapStateToProps)(Routes);
