import P from "prop-types";

FieldErrorMessage.propTypes = {
  show: P.bool,
  children: P.node,
};

export default function FieldErrorMessage({ children, show }) {
  if (!show) return null;

  return <p className="text-danger mt-2 mb-0">{children}</p>;
}
