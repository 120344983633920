import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "rc-switch";
import { Bell } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Button, Card, UncontrolledPopover } from "reactstrap";
import { updateStatusAgence } from "../../actions/agence";
import { showAlert } from "../../utils/alerts";
import { RESPONSABLE_AGENCE } from "../../utils/Constants";

function Toggle(props) {
  //const [checked, setChecked] = useState(true);

  const toggle = () => {
    //setChecked(!props.checked);
    props.updateStatusAgence(!props.checked, showAlert);
  };
  return (
    <div className="d-flex flex-column flex-sm-row align-items-center">
      <Switch
        checked={props.checked}
        onClick={toggle}
        className="switch-small toggle-switch-line toggle-switch-success mb-2 mb-sm-0"
        style={{ width: "20px" }}
      />
      <span className="px-2">{props.checked ? "En ligne" : "Hors Ligne"}</span>
    </div>
  );
}

const HeaderDots = ({ notifications, role, auth, updateStatusAgence }) => {
  const popover = (
    <>
      <div className="bg-neutral-primary mx-3 mt-3  text-center rounded-sm">
        {/* <div className="bg-composed-img-3 --image" /> */}
        <div className="text-secondary px-2 py-3">
          <h4 className="font-size-xl font-weight-bold m-0">Notifications</h4>
          {/*<p className=" mb-0">
            Vous avez <b className="text-success">0</b> nouveaux notifications
            </p>*/}
        </div>
      </div>
      {notifications.payload?.length > 0 && (
        <div className="tabs-animated tabs-animated-shadow tabs-bordered pt-3">
          <div className="scroll-area scroll-area-sm shadow-overflow">
            <PerfectScrollbar
              options={{
                wheelPropagation: false,
              }}>
              {notifications.payload.slice(0, 4).map(item => {
                return (
                  <Card
                    key={item.id}
                    className="card-box shadow-none m-3 px-4 py-2">
                    <h6 className="font-weight-bold mb-1">{item.status}</h6>
                    <div className="font-size-xs text-secondary">
                      <FontAwesomeIcon
                        icon={["far", "clock"]}
                        className="mr-1"
                      />
                      {item.date}
                    </div>
                  </Card>
                );
              })}
            </PerfectScrollbar>
          </div>
        </div>
      )}

      {notifications &&
        notifications.payload &&
        notifications.payload.length === 0 && (
          <div className="d-flex align-items-center justify-content-center my-4">
            <span className="text-black px-3">Pas de notifications !</span>
          </div>
        )}

      <div className="text-center py-3 mx-3">
        <Button
          color="light"
          block
          size="sm"
          className="text-uppercase text-black font-weight-bold"
          tag={NavLink}
          to="/Notification">
          Voir plus{" "}
          <FontAwesomeIcon icon={["fas", "arrow-right"]} className="ml-2" />
        </Button>
      </div>
    </>
  );
  const notif = (
    <span className="d-inline-block pr-2">
      <Button
        id="alertsPopover"
        color="light"
        className="font-size-lg p-0 d-inline-block border-0 text-center d-44 rounded position-relative">
        <Bell />
      </Button>
      <UncontrolledPopover
        target="alertsPopover"
        trigger="legacy"
        className="d-sm-none d-block popover-custom-wrapper popover-custom-sm"
        placement="auto">
        {popover}
      </UncontrolledPopover>
      <UncontrolledPopover
        target="alertsPopover"
        trigger="legacy"
        className="d-none d-sm-block popover-custom-wrapper popover-custom-md"
        placement="auto">
        {popover}
      </UncontrolledPopover>
    </span>
  );

  let OnlineToggle = <></>;

  if (role === RESPONSABLE_AGENCE) {
    OnlineToggle = auth.agenceStatus_isLoading ? (
      <span className="px-3">
        <ClipLoader color={"var(--primary)"} loading={true} />
      </span>
    ) : (
      <Toggle
        checked={auth.user && auth.user.agence.online}
        updateStatusAgence={updateStatusAgence}
      />
    );
  }

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        {notif}
        {OnlineToggle}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role.value,
  auth: state.auth,
  notifications: state.notification.notifications,
});

export default connect(mapStateToProps, { updateStatusAgence })(HeaderDots);
