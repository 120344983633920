import { connect } from 'react-redux';
import { changeRole } from '../../actions/auth';

const HeaderMenu = () => {
  return (
    <>
      <div className="app-header-menu">
        {/*<MySelect
          label="Carte"
          name="carte"
          value={props.role}
          onChange={props.changeRole}
        />*/}
        {/*<span className="pl-3">{props.user && props.user.agence.nom}</span>*/}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  changeRole: role => {
    dispatch(changeRole(role));
    /*toast.success("<h1>Notification successfully opened!</h1>", {
      containerId: "B",
    });*/
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
