import React, { Component } from "react";
import { Button } from "reactstrap";

class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <ErrorBoundaryFallbackComponent />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

const ErrorBoundaryFallbackComponent = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
    <div
      style={{
        marginTop: 20,
        padding: 24,
        width: "60vw",
        borderWidth: 2,
        borderColor: "gray",
        borderStyle: "solid",
        borderRadius: 4,
        color: "gray",
        fontSize: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <span className="mb-3">Une erreur se produit</span>
      <Button
        color="primary"
        className="px-5"
        onClick={() => {
          window.location.replace("/");
        }}>
        Actualiser la page
      </Button>
    </div>
  </div>
);
