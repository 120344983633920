import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import InterBold from "../../assets/fonts/Inter-Bold.ttf";
import InterRegular from "../../assets/fonts/Inter-Regular.ttf";

Font.register({
  family: "Inter",
  fonts: [{ src: InterRegular }, { src: InterBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "10px 20px",
    fontFamily: "Inter",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  walletLogo: {
    width: 210,
  },
  mainInfos: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
  },
  qrcodeImg: {
    width: 280,
    marginRight: 20,
    flexShrink: 0,
  },
  codeBadge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F2F2",
    maxWidth: 160,
    borderRadius: 5,
    padding: "5px 15px",
    margin: "0 auto",
  },
});

export default function VendorQrCodePDF({ imgSrc, vendorCode, vendorName }) {
  return (
    <Document>
      <Page size="A5" style={styles.page} orientation="landscape">
        <View style={styles.mainContent}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              padding: "0 10px",
              width: "100%",
            }}>
            <Image src="/bimbank-mobile-logo.png" style={styles.walletLogo} />
            <Image
              src="/bimbank-mobile-logo-ar.png"
              style={styles.walletLogo}
            />
          </View>
          <View style={styles.mainInfos}>
            <Image src={imgSrc} style={styles.qrcodeImg} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <Text
                style={{
                  fontSize: 24,
                  marginBottom: 15,
                  maxWidth: "50%",
                  textAlign: "center",
                  color: "#57A38C",
                }}>
                {vendorName ? vendorName : "----"}
              </Text>
              <View style={styles.codeBadge}>
                <Text
                  style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    color: "#57A38C",
                  }}>
                  {vendorCode ? vendorCode : "----"}
                </Text>
              </View>
            </View>
          </View>
          {/* <Image src="/bmi_full_logo.png" style={styles.bmiLogo} /> */}
        </View>
      </Page>
    </Document>
  );
}
