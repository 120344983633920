import clsx from "clsx";
import React, { useState } from "react";
import {
  ChevronRight,
  CreditCard,
  DollarSign,
  MapPin,
  MessageCircle,
  Phone,
  PieChart,
  Repeat,
} from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Col, Collapse, Modal, Row } from "reactstrap";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";
import {
  AGENT_COMPENSATION,
  CLIENT,
  EMPLOYE_AGENCE,
  RESPONSABLE_AGENCE,
  VENDOR,
} from "../../utils/Constants";
import SidebarUserbox from "../SidebarUserbox";

const SidebarMenu = props => {
  const { setSidebarToggleMobile, sidebarUserbox } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);
  const [modalRechargeEspece, setModalRechargeEspece] = useState(false);
  const handleModal = () => setModalRechargeEspece(!modalRechargeEspece);

  const Links = [
    {
      id: 1,
      label: "Tableau de bord",
      link: "/Dashboard",
      icon: <PieChart />,
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      id: 2,
      label: "Recharge",
      icon: <Repeat />,
      roles: [CLIENT],
      click: handleModal,
      link: "/Agence",
    },
    {
      id: 5,
      label: "Transactions",
      link: "/Transaction",
      icon: <CreditCard />,
      roles: [EMPLOYE_AGENCE, RESPONSABLE_AGENCE, CLIENT, VENDOR],
    },
    {
      id: 11,
      label: "Cagnottes",
      link: "/Cagnote",
      icon: <DollarSign />,
      roles: [CLIENT],
    },
    {
      id: 7,
      label: "Compensations",
      link: "/Compensation",
      icon: <DollarSign />,
      roles: [RESPONSABLE_AGENCE, AGENT_COMPENSATION],
    },
    {
      id: 8,
      label: "Notifications",
      link: "/Notification",
      icon: <MessageCircle />,
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      id: 3,
      label: "Agences",
      link: "/Agence",
      icon: <MapPin />,
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
    {
      id: 9,
      label: "Contacts",
      link: "/Contact",
      icon: <Phone />,
      roles: [
        EMPLOYE_AGENCE,
        RESPONSABLE_AGENCE,
        AGENT_COMPENSATION,
        CLIENT,
        VENDOR,
      ],
    },
  ];

  const Menu = (
    <>
      <div className="sidebar-header">
        <span>Menu de navigation</span>
      </div>
      {Links.filter(item => item.roles.includes(props.role)).map(item => {
        if (!item.children) {
          if (item.link && !item.click) {
            return (
              <ul key={item.id}>
                <li>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple text-decoration-none"
                    to={item.link}>
                    <span className="sidebar-icon">{item.icon}</span>
                    {item.label}
                    <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                      <ChevronRight />
                    </span>
                  </NavLink>
                </li>
              </ul>
            );
          }
          if (item.link && item.click) {
            return (
              <ul key={item.id}>
                <li>
                  <NavLink
                    onClick={item.click}
                    to={item.link}
                    activeClassName="active"
                    className="nav-link-simple">
                    <span className="sidebar-icon">{item.icon}</span>
                    {item.label}
                    <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                      <ChevronRight />
                    </span>
                  </NavLink>
                </li>
              </ul>
            );
          }
          return null;
        } else {
          return (
            <ul key={item.id}>
              <li>
                <a
                  href="#/"
                  onClick={item.toggle[1]}
                  className={clsx({ active: item.toggle[0] })}>
                  <span className="sidebar-icon">{item.icon}</span>
                  <span className="sidebar-item-label">{item.label}</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={item.toggle[0]}>
                  <ul>
                    {item.children.map((child, key) => {
                      if (child.link) {
                        return (
                          <li key={key}>
                            <NavLink
                              onClick={toggleSidebarMobile}
                              to={child.link}>
                              {child.label}
                            </NavLink>
                          </li>
                        );
                      } else {
                        return (
                          <li key={key}>
                            <NavLink onClick={child.click} to="#">
                              {child.label}
                            </NavLink>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Collapse>
              </li>
            </ul>
          );
        }
      })}
    </>
  );
  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">{Menu}</div>
        <Modal
          zIndex={2000}
          centered
          size="md"
          isOpen={modalRechargeEspece}
          toggle={handleModal}
          contentClassName="border-0">
          <Row className="no-gutters">
            {/* <Col xl="5" className="p-3 p-xl-0">
              <img
                alt="..."
                className="rounded br-xl-right-0 img-fit-container"
                src={svgImage1}
              />
            </Col>*/}
            <Col xl="12">
              <div className="bg-white rounded br-xl-left-0">
                <div className="p-5">
                  <p className="font-size-lg text-black text-center">
                    Merci de vous rendre auprès de l'une de nos agences
                    partenaires.
                  </p>

                  <NavLink
                    className="btn btn-primary btn-block"
                    onClick={handleModal}
                    to="/Agence">
                    Consulter la liste
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = state => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  role: state.auth.role.value,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
