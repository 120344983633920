import { useState } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { login } from "../../actions/auth";
import BimbankLogo from "../../components/common/BimbankLogo";
import { SuspenseLoading } from "../../routers/pageTransition";
import FormRegisterVendor from "./FormRegisterVendor";

function VendorRegister(props) {
  const [formSubmiting, setFormSubmiting] = useState(false);
  const handleFormLoading = () => setFormSubmiting(true);

  if (props.auth.isLoading && !formSubmiting) return <SuspenseLoading />;

  if (!props.auth.isAuthenticated) {
    return (
      <Container>
        <div className="d-flex justify-content-center mt-4 mt-sm-5">
          <div className="register-logo">
            <BimbankLogo full={true} />
          </div>
        </div>
        <div className="w-50 mx-auto py-4 py-sm-5 px-1 px-sm-3">
          <div className="mb-4">
            <h1 className="display-3">S'inscrire</h1>
            <p className="text-black m-0">Créer un compte commerçant</p>
          </div>

          <FormRegisterVendor handleFormLoading={handleFormLoading} />
          <div className="my-4">
            <NavLink to="/Login">
              <span className="pl-2">&lt;- Retour à la page de connexion</span>
            </NavLink>
          </div>
        </div>
      </Container>
    );
  }

  return <Redirect to="/Dashboard" />;
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(VendorRegister);
