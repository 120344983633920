import axios from "axios";
import { expiredToken } from "../utils/alerts";
import {
  AGENCE_ONLINE,
  AGENCE_STATUS_ERROR,
  AGENCE_STATUS_LOADING,
  DATA_LOADING,
  ERROR_AGENCE,
  GET_AGENCES,
  HOST,
} from "./types";

export const getAgences = showAlert => (dispatch, getState) => {
  dispatch({
    type: DATA_LOADING,
    payload: GET_AGENCES,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 30000,
  };
  const access = getState().auth.access;
  if (access) {
    config.headers["Authorization"] = `JWT ${access}`;
  }
  axios
    .get(HOST + `api/agence/partenaire/list/`, config)
    .then(res => {
      dispatch({
        type: GET_AGENCES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: ERROR_AGENCE,
      });
      if (err.response && err.response.status === 401) {
        expiredToken(dispatch, getState().auth.tokenExpired);
      } else {
        showAlert("danger", "Erreur de chargement des agences !");
      }
    });
};

export const updateStatusAgence =
  (status, showAlert) => (dispatch, getState) => {
    // reducer in auth js  ...
    dispatch({ type: AGENCE_STATUS_LOADING });
    const agence = { ...getState().auth.user.agence };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 30000,
    };
    const access = getState().auth.access;
    if (access) {
      config.headers["Authorization"] = `JWT ${access}`;
    }
    agence.online = status;
    axios
      .put(HOST + `api/agence/update/${agence.id}/`, agence, config)
      .then(res => {
        dispatch({
          type: AGENCE_ONLINE,
          payload: res.data,
        });
      })
      .catch(err => {
        dispatch({
          type: AGENCE_STATUS_ERROR,
        });

        if (err.response && err.response.status === 401) {
          expiredToken(dispatch, getState().auth.tokenExpired);
        } else {
          showAlert("danger", "Erreur de changement du status de l'agence !");
        }
      });
  };
