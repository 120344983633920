import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";
import { Button } from "reactstrap";

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  block: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  spinnerColor: PropTypes.string,
  spinnerSize: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default function SubmitButton({
  loading = false,
  disabled = false,
  onClick,
  children,
  block = false,
  outline = false,
  size,
  color = "primary",
  spinnerColor = "white",
  spinnerSize = 20,
  className = "",
  type = "submit",
}) {
  return (
    <Button
      block={block}
      outline={outline}
      color={color}
      type={type}
      size={size}
      className={`text-uppercase mt-3 ${block ? "" : "px-5"} ${
        color === "light" ? "font-weight-bold" : ""
      } ${className}`}
      disabled={loading || disabled}
      onClick={onClick}>
      <div className="d-flex align-items-center justify-content-center">
        <ClipLoader
          color={`var(--${spinnerColor})`}
          loading={loading}
          size={spinnerSize}
        />
        <div className={loading || disabled ? "ml-2" : ""}>{children}</div>
      </div>
    </Button>
  );
}
