import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { RiseLoader } from "react-spinners";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isLoading) {
        return (
          <div
            className="d-flex align-items-center flex-column justify-content-center text-center py-3"
            style={{ height: "80vh" }}>
            <div className="d-flex align-items-center flex-column px-4 pb-2">
              <RiseLoader color={"#F09C22"} loading={true} />
            </div>
            <div className="text-black font-size-xl text-center pt-4">
              Veuillez patientez SVP ...
            </div>
          </div>
        );
      } else if (!auth.isAuthenticated) {
        return <Redirect to="/Login" />;
      } else if (auth.isAuthenticated) {
        return <Component {...props} />;
      }
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
