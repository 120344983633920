import { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const useGeneratePDF = (pdfComponent, pdfName) => {
  const [pdfLoading, setPdfLoading] = useState(false);

  const generate = async () => {
    setPdfLoading(true);

    const blob = await pdf(pdfComponent).toBlob();
    saveAs(blob, `${pdfName}.pdf`);

    setPdfLoading(false);
  };

  return [pdfLoading, generate];
};

export default useGeneratePDF;
