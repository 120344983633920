import { TAXES_ERROR, TAXES_LOADING, GET_TAXES } from "../actions/types.js";

const initialState = {
  taxes: { payload: [], loading: null, error: null },
};

export default function taxesReducer(state = initialState, action) {
  switch (action.type) {
    case TAXES_LOADING:
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: true,
          error: false,
        },
      };
    case TAXES_ERROR:
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: false,
          error: true,
        },
      };
    case GET_TAXES:
      return {
        ...state,
        taxes: {
          ...state.taxes,
          payload: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}
