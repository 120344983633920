export const HOST = process.env.REACT_APP_BASEURL || "https://api-mybim.live/";

export const CHANGE_ROLE = "CHANGE_ROLE";
export const DATA_LOADING = "DATA_LOADING";

export const ERROR_AGENCE = "ERROR_AGENCE";
export const GET_AGENCES = "GET_AGENCES";
export const AGENCE_ONLINE = "AGENCE_ONLINE";
export const AGENCE_STATUS_LOADING = "AGENCE_STATUS_LOADING";
export const AGENCE_STATUS_ERROR = "AGENCE_STATUS_ERROR";
export const UPDATE_SOLDE = "UPDATE_SOLDE";
export const SOLDE_LOADING = "SOLDE_LOADING";

export const SUCCESS_TRANS = "SUCCESS_TRANS";
export const ERROR_TRANS = "ERROR_TRANS";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_FILTRED_TRANSACTIONS = "GET_FILTRED_TRANSACTIONS";
export const GET_SEARCHED_TRANSACTIONS = "GET_SEARCHED_TRANSACTIONS";
export const HISTORY_TRANSACTIONS = "HISTORY_TRANSACTIONS";
export const ADD_TRANSFERT = "ADD_TRANSFERT";
export const ADD_RETRAIT = "ADD_RETRAIT";
export const ADD_RECHARGE = "ADD_RECHARGE";
export const ADD_PAIEMENT = "ADD_PAIEMENT";
export const ADD_PAYBACK = "ADD_PAYBACK";
export const ADD_WALLET_TO_BANK_TRANSFER = "ADD_WALLET_TO_BANK_TRANSFER";
export const ADD_BANK_TO_WALLET_TRANSFER = "ADD_BANK_TO_WALLET_TRANSFER";
export const ADD_PAIEMENT_MASSE = "ADD_PAIEMENT_MASSE";
export const GET_TRANSFERT = "GET_TRANSFERT";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";

export const ERROR_COMP = "ERROR_COMP";
export const GET_COMPENSATIONS = "GET_COMPENSATIONS";
export const ADD_COMPENSATION = "ADD_COMPENSATION";

export const ERROR_CLIENT = "ERROR_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";

//export const ERROR_CLIENT_DIGIPAY = "ERROR_CLIENT_DIGIPAY";
//export const CHECK_CLIENT_DIGIPAY = "CHECK_CLIENT_DIGIPAY";
export const UPDATE_SOLDE_CLIENT_DIGIPAY = "UPDATE_SOLDE_CLIENT_DIGIPAY";
export const UPDATE_SOLDE_AGENT = "UPDATE_SOLDE_AGENT";

export const ERROR_NOTIF = "ERROR_NOTIF";
export const GET_NOTIFS = "GET_NOTIFS";
export const SUCCESS_NOTIF = "SUCCESS_NOTIF";

export const ERROR_EMPLOYE = "ERROR_EMPLOYE";
export const GET_EMPLOYES = "GET_EMPLOYES";

export const AUTH_LOADING = "AUTH_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAN_SESSION = "CLEAN_SESSION";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";

export const UPDATE_PROFIL_FAIL = "UPDATE_PROFIL_FAIL";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_CLIENT_DIGIPAY_SUCCESS = "UPDATE_CLIENT_DIGIPAY_SUCCESS";
export const UPDATE_EMPLOYE_SUCCESS = "UPDATE_EMPLOYE_SUCCESS";
export const UPDATE_RESPONSABLE_SUCCESS = "UPDATE_RESPONSABLE_SUCCESS";
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";

export const ERROR_CAGNOTE = "ERROR_CAGNOTE";
export const GET_CAGNOTES = "GET_CAGNOTES";
export const ADD_CAGNOTE = "ADD_CAGNOTE";
export const PARTICIPATE_CAGNOTE = "PARTICIPATE_CAGNOTE";
export const DELETE_CAGNOTE = "DELETE_CAGNOTE";
export const ADD_DONATION = "ADD_DONATION";
export const UPDATE_DONATION = "UPDATE_DONATION";
export const CLOTURE_CAGNOTE = "CLOTURE_CAGNOTE";

export const ERROR_GRP_PAYEMENT = "ERROR_GRP_PAYEMENT";
export const GET_GRP_PAYEMENTS = "GET_GRP_PAYEMENTS";
export const ADD_GRP_PAYEMENT = "ADD_GRP_PAYEMENT";
export const DELETE_GRP_PAYEMENT = "DELETE_GRP_PAYEMENT";
export const PARTICIPATE_GRP_PAYEMENT = "PARTICIPATE_GRP_PAYEMENT";
export const UPDATE_USER_GRP_PAYEMENT = "UPDATE_USER_GRP_PAYEMENT";
export const DELETE_USER_GRP_PAYEMENT = "DELETE_USER_GRP_PAYEMENT";
//export const UPDATE_DONATION = "UPDATE_DONATION";
//export const CLOTURE_GRP_PAYEMENT = "CLOTURE_GRP_PAYEMENT";

export const GET_TAXES = "GET_TAXES";
export const TAXES_LOADING = "TAXES_LOADING";
export const TAXES_ERROR = "TAXES_ERROR";
